export const IoSocketStatus = () => import('../../node_modules/nuxt-socket-io/lib/components/SocketStatus.js' /* webpackChunkName: "components/io-socket-status" */).then(c => wrapFunctional(c.default || c))
export const CommonsAutoFormatTextField = () => import('../../components/Commons/AutoFormatTextField.vue' /* webpackChunkName: "components/commons-auto-format-text-field" */).then(c => wrapFunctional(c.default || c))
export const CommonsBankCard = () => import('../../components/Commons/BankCard.vue' /* webpackChunkName: "components/commons-bank-card" */).then(c => wrapFunctional(c.default || c))
export const CommonsChangeLanguageLoading = () => import('../../components/Commons/ChangeLanguageLoading.vue' /* webpackChunkName: "components/commons-change-language-loading" */).then(c => wrapFunctional(c.default || c))
export const CommonsCopyButton = () => import('../../components/Commons/CopyButton.vue' /* webpackChunkName: "components/commons-copy-button" */).then(c => wrapFunctional(c.default || c))
export const CommonsCustomQrCode = () => import('../../components/Commons/CustomQrCode.vue' /* webpackChunkName: "components/commons-custom-qr-code" */).then(c => wrapFunctional(c.default || c))
export const CommonsDatePicker = () => import('../../components/Commons/DatePicker.vue' /* webpackChunkName: "components/commons-date-picker" */).then(c => wrapFunctional(c.default || c))
export const CommonsDownloadBanner = () => import('../../components/Commons/DownloadBanner.vue' /* webpackChunkName: "components/commons-download-banner" */).then(c => wrapFunctional(c.default || c))
export const CommonsDownloadByOS = () => import('../../components/Commons/DownloadByOS.vue' /* webpackChunkName: "components/commons-download-by-o-s" */).then(c => wrapFunctional(c.default || c))
export const CommonsDownloadByOs = () => import('../../components/Commons/DownloadByOs.vue' /* webpackChunkName: "components/commons-download-by-os" */).then(c => wrapFunctional(c.default || c))
export const CommonsDownloadPage = () => import('../../components/Commons/DownloadPage.vue' /* webpackChunkName: "components/commons-download-page" */).then(c => wrapFunctional(c.default || c))
export const CommonsFilterForm = () => import('../../components/Commons/FilterForm.vue' /* webpackChunkName: "components/commons-filter-form" */).then(c => wrapFunctional(c.default || c))
export const CommonsGameIframeEmbed = () => import('../../components/Commons/GameIframeEmbed.vue' /* webpackChunkName: "components/commons-game-iframe-embed" */).then(c => wrapFunctional(c.default || c))
export const CommonsGameLoadFailed = () => import('../../components/Commons/GameLoadFailed.vue' /* webpackChunkName: "components/commons-game-load-failed" */).then(c => wrapFunctional(c.default || c))
export const CommonsInviteAndEarnBanner = () => import('../../components/Commons/InviteAndEarnBanner.vue' /* webpackChunkName: "components/commons-invite-and-earn-banner" */).then(c => wrapFunctional(c.default || c))
export const CommonsInviteAndEarnBannerDesktop = () => import('../../components/Commons/InviteAndEarnBannerDesktop.vue' /* webpackChunkName: "components/commons-invite-and-earn-banner-desktop" */).then(c => wrapFunctional(c.default || c))
export const CommonsLanguageSwitcher = () => import('../../components/Commons/LanguageSwitcher.vue' /* webpackChunkName: "components/commons-language-switcher" */).then(c => wrapFunctional(c.default || c))
export const CommonsLoading = () => import('../../components/Commons/Loading.vue' /* webpackChunkName: "components/commons-loading" */).then(c => wrapFunctional(c.default || c))
export const CommonsMaintenance = () => import('../../components/Commons/Maintenance.vue' /* webpackChunkName: "components/commons-maintenance" */).then(c => wrapFunctional(c.default || c))
export const CommonsNoSupportPage = () => import('../../components/Commons/NoSupportPage.vue' /* webpackChunkName: "components/commons-no-support-page" */).then(c => wrapFunctional(c.default || c))
export const CommonsNotFoundPage = () => import('../../components/Commons/NotFoundPage.vue' /* webpackChunkName: "components/commons-not-found-page" */).then(c => wrapFunctional(c.default || c))
export const CommonsNuxtImage = () => import('../../components/Commons/NuxtImage.vue' /* webpackChunkName: "components/commons-nuxt-image" */).then(c => wrapFunctional(c.default || c))
export const CommonsPhoneNumber = () => import('../../components/Commons/PhoneNumber.vue' /* webpackChunkName: "components/commons-phone-number" */).then(c => wrapFunctional(c.default || c))
export const CommonsPopup = () => import('../../components/Commons/Popup.vue' /* webpackChunkName: "components/commons-popup" */).then(c => wrapFunctional(c.default || c))
export const CommonsSkeletonLoader = () => import('../../components/Commons/SkeletonLoader.vue' /* webpackChunkName: "components/commons-skeleton-loader" */).then(c => wrapFunctional(c.default || c))
export const CommonsThemeMode = () => import('../../components/Commons/ThemeMode.vue' /* webpackChunkName: "components/commons-theme-mode" */).then(c => wrapFunctional(c.default || c))
export const Theme4DatePicker = () => import('../../components/Theme4/DatePicker.vue' /* webpackChunkName: "components/theme4-date-picker" */).then(c => wrapFunctional(c.default || c))
export const Theme4DepositSuccessDialog = () => import('../../components/Theme4/DepositSuccessDialog.vue' /* webpackChunkName: "components/theme4-deposit-success-dialog" */).then(c => wrapFunctional(c.default || c))
export const Theme4MainCard = () => import('../../components/Theme4/MainCard.vue' /* webpackChunkName: "components/theme4-main-card" */).then(c => wrapFunctional(c.default || c))
export const Theme4MessageDialog = () => import('../../components/Theme4/MessageDialog.vue' /* webpackChunkName: "components/theme4-message-dialog" */).then(c => wrapFunctional(c.default || c))
export const Theme4PhoneNumber = () => import('../../components/Theme4/PhoneNumber.vue' /* webpackChunkName: "components/theme4-phone-number" */).then(c => wrapFunctional(c.default || c))
export const Theme4Popup = () => import('../../components/Theme4/Popup.vue' /* webpackChunkName: "components/theme4-popup" */).then(c => wrapFunctional(c.default || c))
export const Theme4WithdrawalConfirmDeclineDialog = () => import('../../components/Theme4/WithdrawalConfirmDeclineDialog.vue' /* webpackChunkName: "components/theme4-withdrawal-confirm-decline-dialog" */).then(c => wrapFunctional(c.default || c))
export const VuetifyPhoneInput = () => import('../../components/Vuetify/PhoneInput.vue' /* webpackChunkName: "components/vuetify-phone-input" */).then(c => wrapFunctional(c.default || c))
export const CommonsPicesCircle = () => import('../../components/Commons/Pices/Circle.vue' /* webpackChunkName: "components/commons-pices-circle" */).then(c => wrapFunctional(c.default || c))
export const CommonsPicesStraight = () => import('../../components/Commons/Pices/Straight.vue' /* webpackChunkName: "components/commons-pices-straight" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopBonusGame = () => import('../../components/Theme4/Desktop/BonusGame.vue' /* webpackChunkName: "components/theme4-desktop-bonus-game" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopGameAndSport = () => import('../../components/Theme4/Desktop/GameAndSport.vue' /* webpackChunkName: "components/theme4-desktop-game-and-sport" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopHomeFooter = () => import('../../components/Theme4/Desktop/HomeFooter.vue' /* webpackChunkName: "components/theme4-desktop-home-footer" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopHomeSlider = () => import('../../components/Theme4/Desktop/HomeSlider.vue' /* webpackChunkName: "components/theme4-desktop-home-slider" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopHotGame = () => import('../../components/Theme4/Desktop/HotGame.vue' /* webpackChunkName: "components/theme4-desktop-hot-game" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopLeaderBoard = () => import('../../components/Theme4/Desktop/LeaderBoard.vue' /* webpackChunkName: "components/theme4-desktop-leader-board" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopLiveCasino = () => import('../../components/Theme4/Desktop/LiveCasino.vue' /* webpackChunkName: "components/theme4-desktop-live-casino" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopNavBar = () => import('../../components/Theme4/Desktop/NavBar.vue' /* webpackChunkName: "components/theme4-desktop-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopProfileDropdown = () => import('../../components/Theme4/Desktop/ProfileDropdown.vue' /* webpackChunkName: "components/theme4-desktop-profile-dropdown" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPromotionComponent = () => import('../../components/Theme4/Desktop/PromotionComponent.vue' /* webpackChunkName: "components/theme4-desktop-promotion-component" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopSportGame = () => import('../../components/Theme4/Desktop/SportGame.vue' /* webpackChunkName: "components/theme4-desktop-sport-game" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopSwitchLanguage = () => import('../../components/Theme4/Desktop/SwitchLanguage.vue' /* webpackChunkName: "components/theme4-desktop-switch-language" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobileBonusGame = () => import('../../components/Theme4/Mobile/BonusGame.vue' /* webpackChunkName: "components/theme4-mobile-bonus-game" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobileHomeSlider = () => import('../../components/Theme4/Mobile/HomeSlider.vue' /* webpackChunkName: "components/theme4-mobile-home-slider" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobileLeaderBoard = () => import('../../components/Theme4/Mobile/LeaderBoard.vue' /* webpackChunkName: "components/theme4-mobile-leader-board" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobileMobHeader = () => import('../../components/Theme4/Mobile/MobHeader.vue' /* webpackChunkName: "components/theme4-mobile-mob-header" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobileNavBar = () => import('../../components/Theme4/Mobile/NavBar.vue' /* webpackChunkName: "components/theme4-mobile-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePromotionComponent = () => import('../../components/Theme4/Mobile/PromotionComponent.vue' /* webpackChunkName: "components/theme4-mobile-promotion-component" */).then(c => wrapFunctional(c.default || c))
export const Theme4PagesBonusPage = () => import('../../components/Theme4/Pages/BonusPage.vue' /* webpackChunkName: "components/theme4-pages-bonus-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4PagesDepositApply = () => import('../../components/Theme4/Pages/DepositApply.vue' /* webpackChunkName: "components/theme4-pages-deposit-apply" */).then(c => wrapFunctional(c.default || c))
export const Theme4PagesPromotionPage = () => import('../../components/Theme4/Pages/PromotionPage.vue' /* webpackChunkName: "components/theme4-pages-promotion-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4PagesRecentlyPage = () => import('../../components/Theme4/Pages/RecentlyPage.vue' /* webpackChunkName: "components/theme4-pages-recently-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4PagesWithdrawalApply = () => import('../../components/Theme4/Pages/WithdrawalApply.vue' /* webpackChunkName: "components/theme4-pages-withdrawal-apply" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPagesBonusPage = () => import('../../components/Theme4/Desktop/Pages/BonusPage.vue' /* webpackChunkName: "components/theme4-desktop-pages-bonus-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPagesGamePage = () => import('../../components/Theme4/Desktop/Pages/GamePage.vue' /* webpackChunkName: "components/theme4-desktop-pages-game-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPagesLoginPage = () => import('../../components/Theme4/Desktop/Pages/LoginPage.vue' /* webpackChunkName: "components/theme4-desktop-pages-login-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPagesPromotionPage = () => import('../../components/Theme4/Desktop/Pages/PromotionPage.vue' /* webpackChunkName: "components/theme4-desktop-pages-promotion-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPagesRecentlyPage = () => import('../../components/Theme4/Desktop/Pages/RecentlyPage.vue' /* webpackChunkName: "components/theme4-desktop-pages-recently-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPagesReferFriend = () => import('../../components/Theme4/Desktop/Pages/ReferFriend.vue' /* webpackChunkName: "components/theme4-desktop-pages-refer-friend" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPagesRegisterPage = () => import('../../components/Theme4/Desktop/Pages/RegisterPage.vue' /* webpackChunkName: "components/theme4-desktop-pages-register-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPaymentMethodBankTransfer = () => import('../../components/Theme4/Desktop/PaymentMethod/BankTransfer.vue' /* webpackChunkName: "components/theme4-desktop-payment-method-bank-transfer" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPaymentMethodBankWithdrawal = () => import('../../components/Theme4/Desktop/PaymentMethod/BankWithdrawal.vue' /* webpackChunkName: "components/theme4-desktop-payment-method-bank-withdrawal" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPaymentMethodCryptoCurrency = () => import('../../components/Theme4/Desktop/PaymentMethod/CryptoCurrency.vue' /* webpackChunkName: "components/theme4-desktop-payment-method-crypto-currency" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPaymentMethodMyDeposit = () => import('../../components/Theme4/Desktop/PaymentMethod/MyDeposit.vue' /* webpackChunkName: "components/theme4-desktop-payment-method-my-deposit" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPaymentMethodMyWithdrawal = () => import('../../components/Theme4/Desktop/PaymentMethod/MyWithdrawal.vue' /* webpackChunkName: "components/theme4-desktop-payment-method-my-withdrawal" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPaymentMethodOnlinePayment = () => import('../../components/Theme4/Desktop/PaymentMethod/OnlinePayment.vue' /* webpackChunkName: "components/theme4-desktop-payment-method-online-payment" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPaymentMethodQuickPayment = () => import('../../components/Theme4/Desktop/PaymentMethod/QuickPayment.vue' /* webpackChunkName: "components/theme4-desktop-payment-method-quick-payment" */).then(c => wrapFunctional(c.default || c))
export const Theme4DesktopPaymentMethodWithdrawalCrypto = () => import('../../components/Theme4/Desktop/PaymentMethod/WithdrawalCrypto.vue' /* webpackChunkName: "components/theme4-desktop-payment-method-withdrawal-crypto" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePagesBonusPage = () => import('../../components/Theme4/Mobile/Pages/BonusPage.vue' /* webpackChunkName: "components/theme4-mobile-pages-bonus-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePagesGamePage = () => import('../../components/Theme4/Mobile/Pages/GamePage.vue' /* webpackChunkName: "components/theme4-mobile-pages-game-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePagesLoginPage = () => import('../../components/Theme4/Mobile/Pages/LoginPage.vue' /* webpackChunkName: "components/theme4-mobile-pages-login-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePagesPromotionPage = () => import('../../components/Theme4/Mobile/Pages/PromotionPage.vue' /* webpackChunkName: "components/theme4-mobile-pages-promotion-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePagesRecentlyPage = () => import('../../components/Theme4/Mobile/Pages/RecentlyPage.vue' /* webpackChunkName: "components/theme4-mobile-pages-recently-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePagesReferFriend = () => import('../../components/Theme4/Mobile/Pages/ReferFriend.vue' /* webpackChunkName: "components/theme4-mobile-pages-refer-friend" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePagesRegisterPage = () => import('../../components/Theme4/Mobile/Pages/RegisterPage.vue' /* webpackChunkName: "components/theme4-mobile-pages-register-page" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePaymentMethodBankTransfer = () => import('../../components/Theme4/Mobile/PaymentMethod/BankTransfer.vue' /* webpackChunkName: "components/theme4-mobile-payment-method-bank-transfer" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePaymentMethodBankWithdrawal = () => import('../../components/Theme4/Mobile/PaymentMethod/BankWithdrawal.vue' /* webpackChunkName: "components/theme4-mobile-payment-method-bank-withdrawal" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePaymentMethodCryptoCurrency = () => import('../../components/Theme4/Mobile/PaymentMethod/CryptoCurrency.vue' /* webpackChunkName: "components/theme4-mobile-payment-method-crypto-currency" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePaymentMethodMyDeposit = () => import('../../components/Theme4/Mobile/PaymentMethod/MyDeposit.vue' /* webpackChunkName: "components/theme4-mobile-payment-method-my-deposit" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePaymentMethodMyWithdrawal = () => import('../../components/Theme4/Mobile/PaymentMethod/MyWithdrawal.vue' /* webpackChunkName: "components/theme4-mobile-payment-method-my-withdrawal" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePaymentMethodOnlinePayment = () => import('../../components/Theme4/Mobile/PaymentMethod/OnlinePayment.vue' /* webpackChunkName: "components/theme4-mobile-payment-method-online-payment" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePaymentMethodQuickPayment = () => import('../../components/Theme4/Mobile/PaymentMethod/QuickPayment.vue' /* webpackChunkName: "components/theme4-mobile-payment-method-quick-payment" */).then(c => wrapFunctional(c.default || c))
export const Theme4MobilePaymentMethodWithdrawalCrypto = () => import('../../components/Theme4/Mobile/PaymentMethod/WithdrawalCrypto.vue' /* webpackChunkName: "components/theme4-mobile-payment-method-withdrawal-crypto" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
